<template>
    <section class="pt-4">
        <div v-if="pedido.id_pedido == null" class="row mx-0 mb-3">
            <div class="col-12 d-flex mb-2">
                <div style="width:34px;height:25px;" />
                <p class="text-general f-17 f-600">Cliente del Lechero</p>
            </div>
            <div class="col-12 d-middle">
                <div class="text-center" style="width:34px;height:25px;">
                    <i class="icon-cliente f-17 text-general" />
                </div>
                <p class="col-auto px-0 text-general f-300 f-14">
                    {{ pedido.digitado_cliente_nombre || 'No Registra' }}
                </p>
            </div>
            <div class="col-12 d-middle mt-2">
                <div class="text-center" style="width:34px;height:25px;">
                    <i class="icon-phone f-17 text-general" />
                </div>
                <p class="col-auto px-0 text-general f-300 f-14">
                    {{ pedido.digitado_cliente_telefono || 'No Registra' }}
                </p>
            </div>
            <div class="col-12 d-flex mt-2">
                <div class="text-center" style="width:34px;height:25px;">
                    <i class="icon-map-marker-outline f-20 text-general" />
                </div>
                <div class="col px-0">
                    <p class="text-general f-500 f-17">
                        Dirección del recibido
                    </p>
                    <p class="text-general f-300 f-15 mt-1">
                        {{ pedido.direccion }}
                    </p>
                </div>
            </div>
            <div class="col-12 mt-3" style="background-color:#F5F5F5;height:5px;" />
        </div>
        <div v-if="pedido.id_pedido != null" class="row mx-0 mb-3">
            <div class="col-12 d-flex mb-2">
                <div style="width:34px;height:34px;" />
                <p class="text-general f-17 f-600">Cliente</p>
            </div>
            <div class="col-12 d-middle">
                <img :src=" pedido.datos_cliente.avatar" class="rounded-circle border obj-cover mr-1" width="34" height="34" />
                <p class="col-auto px-0 text-general f-300 f-15">
                    {{ pedido.datos_cliente.nombre }}
                </p>
                <p class="col-auto text-general ml-auto">
                    {{ pedido.datos_cliente.cant_pedidos }} <i class="icon-pedidos text-general f-18" />
                </p>
            </div>
            <div class="col-12 d-middle mt-2">
                <div class="text-center mr-1" style="width:34px;height:34px;">
                    <i class="icon-calendar-clock f-17 text-general" />
                </div>
                <p class="col-auto px-0 text-general f-300 f-17">
                    {{ pedido.fecha_entrega | helper-fecha('ddd DD MMMM hh:mm a') }}
                </p>
            </div>
            <div class="col-12 d-flex mt-2">
                <div class="text-center mr-1" style="width:34px;height:34px;">
                    <i class="icon-map-marker-outline f-20 text-general" />
                </div>
                <div class="col px-0">
                    <p class="text-general f-500 f-17">
                        Dirección del recibido
                    </p>
                    <p class="text-general f-300 f-15 mt-1">
                        {{ pedido.direccion }}
                    </p>
                </div>
            </div>
            <div class="col-12 d-middle mt-2">
                <div class="text-center mr-1" style="width:34px;height:34px;">
                    <i class="icon-phone f-17 text-general" />
                </div>
                <p class="col-auto px-0 text-general f-300 f-15">
                    {{ pedido.datos_cliente.telefono || 'No registra' }}
                </p>
            </div>
            <div class="col-12 mt-3" style="background-color:#F5F5F5;height:5px;" />
        </div>
        <div class="row mx-0 mb-3">
            <div class="col-12 d-flex mb-2">
                <div class="text-center" style="width:34px;height:34px;">
                    <i class="icon-calendar-clock f-17 text-general" />
                </div>
                <p class="text-general f-17 f-600">Atiende</p>
            </div>
            <div class="col-12 d-middle">
                <div style="width:34px;height:34px;" />
                <img :src="pedido.datos_tienda.logo_mini" class="br-6 border obj-cover mr-1" width="46" height="46" />
                <p class="col-auto px-0 text-general f-300 f-15">
                    {{ pedido.datos_tienda.nombre }}
                </p>
                <p class="col-auto text-general ml-auto">
                    {{ pedido.datos_tienda.cant_pedidos }} <i class="icon-pedidos text-general f-18" />
                </p>
            </div>
            <div class="col-12 d-middle mt-2">
                <div class="text-center mr-1" style="width:34px;height:34px;">
                    <i class="icon-calendar-clock f-17 text-general" />
                </div>
                <p class="col-auto px-0 text-general f-300 f-17">
                    {{ pedido.fecha_entrega | helper-fecha('ddd DD MMMM hh:mm a') }}
                </p>
            </div>
            <div class="col-12 d-flex mt-2">
                <div class="text-center mr-1" style="width:34px;height:34px;">
                    <i class="icon-map-marker-outline f-20 text-general" />
                </div>
                <div class="col px-0">
                    <p class="text-general f-500 f-17">
                        Dirección del recibido
                    </p>
                    <p class="text-general f-300 f-15 mt-1">
                        {{ pedido.direccion }}
                    </p>
                </div>
            </div>
            <div class="col-12 d-middle mt-2">
                <div class="text-center mr-1" style="width:34px;height:34px;">
                    <i class="icon-phone f-17 text-general" />
                </div>
                <p class="col-auto px-0 text-general f-300 f-15">
                    {{ pedido.datos_tienda.telefono_celular }}
                </p>
            </div>
            <div class="col-12 mt-3" style="background-color:#F5F5F5;height:5px;" />
        </div>
        <div class="row mx-0 mb-3">
            <div class="col-12 d-flex">
                <div class="text-center" style="width:34px;height:34px;">
                    <i class="icon-pedidos f-17 text-general" />
                </div>
                <p class="text-general f-17 f-600">Más informacion</p>
            </div>
            <div class="col-12 d-flex">
                <div class="col px-0">
                    <p class="text-general f-16 f-500">
                        Instrucciones:
                    </p>
                    <p class="text-general f-15 f-300">
                        {{ pedido.instrucciones }}
                    </p>
                </div>
            </div>
            <div class="col-12 mt-3" style="background-color:#F5F5F5;height:5px;" />
        </div>
        <div class="row mx-0 mb-3">
            <div class="col-12 d-flex">
                <div style="width:34px;height:34px;">
                    <i class="icon-comision f-15 text-general" />
                </div>
                <p class="text-general f-17 f-600">Detalle de cobro</p>
            </div>
            <div class="col-12 d-flex">
                <div style="width:34px;" />
                <div class="col px-0">
                    <div class="row mx-0 mb-2">
                        <p class="col-auto px-0 f-15 text-general f-300">
                            Productos ({{ resumen.cantidad_productos }})
                        </p>
                        <p class="col-auto px-0 f-15 ml-auto text-general f-500">
                            {{ convertMoney(resumen.val_productos, pedido.idm_moneda) }}
                        </p>
                    </div>
                    <div class="row mx-0 mb-2">
                        <p class="col-auto px-0 f-15 text-general f-300">
                            Descuento
                        </p>
                        <p class="col-auto px-0 f-15 ml-auto text-general f-500">
                            {{ convertMoney(resumen.val_descuentos,pedido.idm_moneda) }}
                        </p>
                    </div>
                    <div v-if="resumen.id_cupon" class="row mx-0 mb-2">
                        <i class="icon-cupon f-15 text-general mr-2" />
                        <p class="col-auto px-0 f-15 text-general f-300">
                            {{ resumen.codigo_cupon }}
                        </p>
                        <p class="col-auto px-0 f-15 ml-auto text-general f-500">
                            {{ convertMoney(resumen.val_cupon, pedido.idm_moneda) }}
                        </p>
                    </div>
                    <div class="row mx-0 mb-2">
                        <p class="col-auto px-0 f-15 text-general f-300">
                            Alianza  {{ $config.vendedor }}
                        </p>
                        <p class="col-auto px-0 f-15 ml-auto text-general f-500">
                            {{ convertMoney(resumen.val_condicion,pedido.idm_moneda) }}
                        </p>
                    </div>
                    <div class="row mx-0 mb-2 border-top">
                        <p class="col-auto px-0 f-15 text-general f-500">
                            SUB-TOTAL
                        </p>
                        <p class="col-auto px-0 f-15 ml-auto text-general f-500">
                            {{ convertMoney(resumen.subtotal,pedido.idm_moneda) }}
                        </p>
                    </div>
                    <div class="row mx-0 py-1" />
                    <div class="row mx-0 mb-2">
                        <p class="col-auto px-0 f-15 text-general f-300">
                            Impuestos
                        </p>
                        <p class="col-auto px-0 f-15 ml-auto text-general f-500">
                            {{ convertMoney(resumen.val_impuestos, pedido.idm_moneda) }}
                        </p>
                    </div>
                    <div class="row mx-0 mb-2">
                        <p class="col-auto px-0 f-15 text-general f-300">
                            Domicilio
                        </p>
                        <p class="col-auto px-0 f-15 ml-auto text-general f-500">
                            {{ convertMoney(resumen.val_domicilios,pedido.idm_moneda) }}
                        </p>
                    </div>

                    <hr class="my-2 border" />
                    <div class="row mx-0 mb-2">
                        <p class="col-auto px-0 f-15 text-general f-500">
                            TOTAL
                        </p>
                        <p class="col-auto px-0 f-15 ml-auto text-general f-500">
                            {{ convertMoney(resumen.val_total,pedido.idm_moneda) }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3" style="background-color:#F5F5F5;height:5px;" />
        </div>
    </section>
</template>

<script>
export default {
    props:{
        pedido:{
            type:Object,
            default:()=>({datos_cliente:{},datos_tienda:{}})
        },
        resumen:{
            type:Object,
            default:()=>({})
        },
    },

}
</script>
<style lang="scss" scoped>
.ganancias-leechero{
    background-color: #F4FDF8;
    border: 1px solid #28D07B;
    height: 33px;
    .price{
        color: #28D07B;
        font-weight: 600;
    }
}
</style>
